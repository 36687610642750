body {
  text-align: center;
  background-color: #090909;
  margin: 0;
  font-family:"filicudi-solid", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.header {
  width: 100%;
  height:  132px;
  background-color: #273a63;
  background-image: url('img/blue_map.jpg');
  background-position: center center;
}
.header_cont {
  width: var(--maximum-page-width);
  margin: 0 auto;
}

.blue_link a, .blue_link a:hover {
  font-weight: bold;
  color: var(--brand-blue);
  text-decoration: none;
}
.header_logo {
  cursor: pointer;
  float: left;
  margin: 10px 40px;
  background-image: url('img/logo_on_blue.png');
  background-position: center;
  background-size:  contain;
  background-repeat: no-repeat;
  height: calc(145px - 32px);
  width: calc(235px - 80px);
}
.header_right {
  float: right;
  margin: 0px 40px 0px 0px;
  width: calc(100% - 235px - 40px);
  height: calc(132px);
  color: #FFF;
  text-align: right;
  vertical-align: middle;
  line-height:132px;

}
.header_menu_item {
  font-size: 18px;
  display: inline-block;
  padding-left: 32px;
  vertical-align: middle;
  cursor: pointer;
}
.header_menu_item {
  color: #FFF;
}

.header_menu_item:hover {
  color: var(--brand-yellow);
}

.header_sub {
  margin: 0 auto;
  width: 100%;
  min-height: 28px;
  background-color: var(--brand-yellow);
  padding: 10px;
  line-height: 1.0em;
}

.page {
  display: inline-block;
  width: var(--maximum-page-width);
  background-color: #FFF;
}
.admin_page {

  display: inline-block;
  width: 100%;
  background-color: #FFF;
}

.text_block_white {
  text-align: left;
  padding: 40px;
  background-image: url('img/white_map.jpg');
  background-position: center center;
  color: #000;
}

.text_block_black {
  text-align: left;
  padding: 40px;
  background-image: url('img/black_map.jpg');
  background-position: center center;
  color: #FFF;
}
.text_block_blue {
  text-align: left;
  padding: 40px;
  background-image: url('img/blue_map.jpg');
  background-position: center center;
  color: #FFF;
}
.text_block_blue a, .text_block_blue a:hover {
  color: #FFF;
}
.text_block_black a, .text_block_black a:hover {
  color: #FFF;
}
.text_block_white a, .text_block_white a:hover {
  color: #000;
}


.full_footer {
  color: #000;
  background-color: var(--brand-yellow);
  width: var(--maximum-page-width);
  margin: 0px auto;
  padding-bottom: 20px;
}
.header_menu_img {
  margin-right: 6px;
  margin-top: 2px;
}
.header_mobile_menu_img {
  margin-right: 16px;
  margin-top: 0px;
}
.mobile_menu_li a {
  text-decoration: none;
  color: #FFF;

}
.mobile_menu_li a:hover {
  color: var(--brand-yellow);
}

.copyright {
  padding: 4px 0px;
  color: #FFF;
  font-size: 12px;
}
.list ul {
  list-style-type: none;
  padding-left: 40px;
}
.mobile_list ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
.mobile_list li {
  padding: 16px 0px;
  font-size: 20px;
  border-top: solid 2px #000;
}
.mobile_list {
  font-family:"filicudi-solid", sans-serif;
  width: calc(100%);
}
.list li {
  padding: 4px 0px;
}
.list {
  font-family: europa, sans-serif;
  width: 140px;
  text-align: left;
  padding-top: 24px;
  float: left;
  font-size: 16px;
  line-height: 1.1em;
}
.list_head {
  font-family:"filicudi-solid", sans-serif;
  font-weight: bold;
  font-size: 18px;
}
.footer_address_box {
  padding-top: 24px;
  float: right;
  width: calc(100% - 420px);
}
.footer_abox_left {
  float: right;
  margin-right: 40px;
}
.footer_abox_right {
  float: right;
  margin-left: 16px;
}
.mobile_footer_abox_left {
  float: left;
}
.mobile_footer_abox_right {
  float: right;
  margin-left: 16px;
}
.hamburger_icon {
  cursor: pointer;
  display: none;
  background-image: url('img/hamburger.png');
  background-position: center center;
  background-repeat: no-repeat;
  width: 57px;
  height: calc(132px - 40px);
  margin: 20px 0px;
  float: right;
  background-size: contain;
}
.clear {
  clear: both;
}
.mobile_menu {
  display: none;
  position: absolute;
  top: 132px;
  left: 0px;
  width: 100%;
  background-color: var(--brand-blue);
  line-height: 80px;
  z-index: 3;
}
.mobile_menu_li {
  text-align: center;
  width: 100%;
  display: inline-block;
  font-size: 24px;
  border-top: solid 2px #FFF;

}
.footer_abox_left {
  text-align: right;
  line-height: 1.2em;
}
.footer_abox_right {
  margin-right: 16px;
}

.less_margin h5 {
  margin: 0px;
}
.white_link, .white_link a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}
.white_link:hover, .white_link a:hover {
  color: var(--brand-yellow);
}

.black_link a {
  color: #000;
  font-weight: bold;
  text-decoration: none;
}
.black_link a:hover {
  color: var(--brand-blue);
}
.black_link:hover, .black_link {
  color: #000;
  font-weight: bold;
  text-decoration: none;
}
.yellow_link a {
  color: var(--brand-yellow);
  font-weight: bold;
  text-decoration: none;
}
.yellow_link a:hover {
  color: var(--brand-yellow);
}
.blue_link a {
  color: var(--brand-blue);
  font-weight: bold;
  text-decoration: none;
}
.blue_link a:hover {
  color: var(--brand-blue);
}
.social_img {
  margin: 4px 0px 4px 8px;
  cursor: pointer;
}
h1 {

}

p {
  font-family: europa, sans-serif;
  font-size: 18px;
}
.brand_color_text {
  color: var(--brand-yellow);
}

.brand_dark_color_text {
  color: var(--brand-blue);
}
.mobile_footer {
  font-size: 1.2em;
  display: none;
  color: #000;
  background-color: var(--brand-yellow);
  width:  100%;
  margin: 0px auto;
  padding: 20px 40px 10px 40px;
  text-align: center;
}
.image2 {
  height: 424px;
  background-repeat: no-repeat;
  background-size: auto 125%;
  padding-top: 340px;
  color: #FFF;
  font-size: 30px;
  line-height: 1.1em;
  text-align: left;
  padding-left: 40px;
  padding-right: 40px;
  text-shadow: 2px 2px 5px rgba(0,0,0,0.6);
}
.strasbourg_winter_header {
  height: 407px;
  background-repeat: no-repeat;
  background-size: auto 120%;
  padding-top: 290px;
  color: #FFF;
  font-size: 30px;
  line-height: 1.1em;
  text-align: left;
  padding-left: 40px;
  padding-right: 40px;
  text-shadow: 2px 2px 5px rgba(0,0,0,0.6);
}
.image2 {
  height: 600px;
  background-image: url('img/image2.jpg');
  padding-top: 40px;
}
.left_text_box {
  width: 70%;
  display: inline-block;
}

.static_header_with_stuff_inside {
  width:100%;
  height: 407px;
}
.carousel_item {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width:100%;
  height:424px;
}
.left_angled_area_text_carousel {
  color:#FFF;
  width:40%;
  float:left;
  margin-top:80px;
  padding:20px;
}
.right_angled_area_text_carousel {
  color:#FFF;
  width:40%;
  float:right;
  margin-top:80px;
  padding:20px;
}
.less_top_margin {
  margin-top:40px;
}
.square_ad_image {
    display: none;
  }
.square_ad_button {
  margin-top:-400px;
  width:100%;
  height:200px;
  position: absolute;
  color:#FFF;
}
@media (max-width: 640px) {
  .left_angled_area_text_carousel {
    display: none;
  }
  .right_angled_area_text_carousel {
    display: none;
  }
  .carousel_item {
    background-image: none;
    height: auto;
  }
  .square_ad_image {
    width:100%;
    display: inline-block;
  }
}
.yellow_heading_caro {
  color:var(--brand-yellow);
  font-size: 40px;
  line-height: 1.0em;
}
.yellow_heading_caro_smaller {
  color:var(--brand-yellow);
  font-size: 30px;
  line-height: 1.0em;
}

.strasbourg_pioneers {
  height:424px;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top right;
  padding-top:300px;
  text-align: left;
  padding-left:40px;

}
.small_promo_name {
  color: #fff;
  font-style: italic;
  font-size: 10px;
}

.serving {
  display: inline-block;
  margin-right: 20px;
}

@media (max-width: 640px) {
  .left_text_box {
    width: 100%;
  }
}



@media (max-width: 840px) {
  .image2 {
    height: 590px;
  }
}
@media (max-width: 720px) {
  .image2 {
    height: 630px;
  }
}
.schedule_button {
  z-index:2;
  position: absolute;
  left: 40px;
}
@media (max-width: 700px) {
  .image1, .image2 {
    height: 300px;
    background-repeat: no-repeat;
    padding-top: 200px;
    color: #FFF;
    font-size: 20px;
    line-height: 1.1em;
    text-align: left;
    padding-left: 40px;
  }
  .static_header_with_stuff_inside {
    width:100%;
    height: 300px;
  }
  .strasbourg_pioneers {
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: top right;
    padding-top:210px;
    text-align: right;
    padding-right: 20px;
    text-align: right;
  }
    .strasbourg_pioneers {

  }
  .heating_up_header {
    background-size: auto 105%;
  
  }
  .schedule_button {
    z-index:2;
    position:absolute;
    right: 20px;
  }
  .image2 {
    padding-top: 40px;
    height: 500px;
  }
  .full_footer {
    display: none;
  }
  .mobile_footer {
    display: inline-block;

  }
  .list_head {
    font-size: 22px;
    color: #FFF;
    background-color: #000;
    width: 100%;
    padding-top: 2px;
  }
  .copyright {
    padding: 10px 0px;
    font-size: 14px;
  }

}
@media (max-width: 570px) {
  .image2 {
    height: 620px;
  }
}
@media (max-width: 540px) {
  .image2 {
    height: 620px;
  }

}

@media (max-width: 980px) {
  
  .header_cont {
    width: 100%;
  }
  
  .page {
    width: 100%;
  }

  .full_footer {
  
    width: 100%;
  }
}


@media (max-width: 700px) {

  .hamburger_icon {
    display: inline-block;
  }

  .header_logo {
    /*margin: 10px 40px;*/
    background-image: url('img/icon_on_blue.png');
/*    height: calc(132px - 32px);
    width: calc(135px - 32px);*/
    height: calc(132px - 20px);
    width: calc(176px - 80px);
  }

  .text_header_items {
    display: none;
  }



}

.form-control.light {
  background-color: #FFF;
  color: #000;
}
.form-control.dark {
  background-color: #000;
  color: #FFF;
}
.form-control.blue {
  background-color: var(--brand-blue-darker-yet);
  color: #FFF;
}
.form-control.light:focus {
  color: #000;
}
.form-control.dark:focus {
  color: #FFF;
}
.form-control.blue:focus {
  color: #FFF;
}
.form-control.light:disabled {
  background-color: white;
  color: grey;
}
.form-control.dark:disabled {
  background-color: black;
  color: grey;
}
.form-control.blue:disabled {
  background-color: black;
  color: grey;
}
.form-control.textarea.readonly {
  color: grey;
}



.form-control {
  font-family: sans-serif;
}
.form-control.text, .form-control.select {
  font-family: sans-serif;
  height: 48px;
}
.form-control.textarea {
  font-family: sans-serif;
}
.spacer_short {
  width: 100%;
  font-size: 15px;
}
#payment-form {
  margin-top: -32px;
}

.form-control.readonly {
  color: grey;
}

.form-check-input {
  background-color: #FFF;

}



.form_left {
  width: 75%;
  text-align: left;
  display: inline-block;

}
.form_right {
  width: 75%;
  text-align: right;
  display: inline-block;
}

@media (max-width: 600px) {

  .form_left {
    width: 100%;
    display: inline-block;
  }
  .form_right {
    width: 100%;
    display: inline-block;
  }



}
.form_error_message {
  color: var(--brand-yellow)
}
.react-datepicker-wrapper input {
    
    color: #FFF;
    
    background-color: black;
    width: 100%;
    padding: 0.375rem 0.75rem;
    
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #364d8c;
    --bs-btn-border-color: #364d8c;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #364d8c;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #364d8c;
    --bs-btn-active-border-color: #364d8c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #364d8c;
    --bs-btn-disabled-border-color: #364d8c;
}
.pleaseDrag {
  background-color: var(--brand-blue);
  text-align: left;
  color: #FFF;
  font-size: 16px;
  margin-top: -490px;
  position: absolute;
  z-index: 100;
  padding: 8px 16px;
  margin-left: 10px;
  font-family: europa, sans-serif;
  width: 910px;
  border-radius: 2px;
}
.serviceAvailable, .preorderAvailable, .notAvailable {
  background-color: var(--brand-blue);
  margin-top: -104px;
  width: 500px;
  position: absolute;
  z-index: 100;
  color: #FFF;
  min-height: 80px;
  border-radius: 2px;
  font-family: europa, sans-serif;
  text-align: left;
  
  margin-left: 10px;
}
.sa_left {
  float: left;
  width: 60px;
  padding: 18px;
}
.sa_right {
  padding: 14px 16px;
  width:  calc(100% - 60px);
  float: right;
}





@media (max-width: 980px) {

  .pleaseDrag {
    width: calc(100% - 70px);
  }


  .serviceAvailable, .preorderAvailable, .notAvailable {
    width: calc(100% - 70px);
  }

}
.packageDesc {
  font-family: europa, sans-serif;
  font-size: 18px;
  margin-top: -6px;
}

.packagePrice {
  font-family: europa, sans-serif;
  font-size: 36px;
  display: block;
  font-weight: 900;
}
.packagePriceSmaller {
  font-family: europa, sans-serif;
  font-size: 20px;
  font-weight: 100;
}
.packagePriceSmallerYet {
  font-family: europa, sans-serif;
  font-size: 16px;
  font-weight: 100;
}
.packagePriceEvenSmallerYet {
  font-family: europa, sans-serif;
  font-size: 14px;
  font-weight: 100;
}
.packagePriceEvenMoreSmallerYet {
  font-family: europa, sans-serif;
  font-size: 12px;
  font-weight: 100;
}
.packageSpeed {
  font-family: europa, sans-serif;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 16px;
}


.speedOnPlanLargerer {
  font-size: 20px;
  font-weight: bold;
}
.speedOnPlanLarger {
  font-size: 20px;
  font-weight: bold;
}
.speedOnPlan {
  font-size: 14px;
  font-family: europa, sans-serif;
}
.internetPackage {
  width: 380px;
  margin-right: 40px;
  margin-bottom: 0px;
  display: inline-block;
}
.finePrint {
  font-size: 12px;
  font-family: europa, sans-serif;
  position: absolute;
  margin-top: -34px;
  margin-left: 80px;
}
@media (max-width: 440px) {

  .internetPackage {
    width: 100%;
  }

}

.block_text_align_center {
  text-align: center;
}
#stage_existing_login {
  text-align: left;
  width: 50%;
  margin: 0 auto;
}
@media (max-width: 650px) {

  #stage_existing_login {
    width: 100%;
  }

}



.general_table .trOdd {
  background-color: rgba(0, 0, 0, 0.25);

}
.receipt_table .trOdd {
  background-color: rgba(0, 0, 0, 0.05);

}


.receipt_table .trOdd td, .receipt_table .trEven td {
    padding: 20px;
}

/*.trOdd.td_left td {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}*/

.inline {
  display: inline-block;
}
.general_table {
  width: 100%;
  color: #FFF;
  font-family: europa, sans-serif;

}
.general_table td {
  font-weight: normal;

}
.general_table td {
 
}
.general_table tr:hover {
 background-color: rgba(0, 0, 0, 0.05);
  

}

.rounded_table .trFirst {
  padding: 20px;
}



.account_info_table {
  width: 100%;
}

.rounded_table {
  background-color: rgba(255, 255, 255, 1.0);
  border-radius: 25px;
  padding: 0px;
  margin-bottom: 10px;
}

.receipt_table {
  width: 100%;
  color: #000;
  font-family: europa, sans-serif;

}
.receipt_table td {
  font-weight: normal;

}
.receipt_table td {
 
}


.receipt_table th {
 

}

.right_td_item {
  text-align: right;
  padding: 0px 20px;
}

.shift_back_button_on_form {
  margin-left: 10px;
}


.small_table_font {
  font-size: 12px;
  line-height: 1.4em;
}

.service_action_line {
  font-size: 16px;
  font-style: italic;
  margin-top: -16px;
  padding-left: 20px;
}
.account_info_line {
  font-family: europa, sans-serif;
  line-height: 1.5;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
    .btn {
      display: none !important;
    }

    .receipt_table .trOdd td, .receipt_table .trEven td {
        padding: 20px 0px;
    }
    .rounded_table .trFirst {
      padding: 20px 0px;
    }

    .right_td_item {
      padding: 0px 0px;
    }



}

.p_after_h {
  margin:  0px;
  font-family: europa, sans-serif;
  margin-top: -10px;
}


.an_error {
  color: red;
}
.small_error_text {
  line-height: 1.2em;
  font-size: 15px;
  font-style: italic;
}
.receipt_table_label {
  text-align: left;
}
.hidden {
  display: none;
}