@use 'sass:meta';


// Override default bootstrap variables before the import
$body-bg: #000;




// Colors

$brand-blue: #253962;
$brand-yellow: #f2b500;


:root {
    
  --maximum-page-width: 980px;
  --minimum-page-width: 360px;

  --brand-blue-darker: #{darken($brand-blue, 2%)};
  --brand-blue-darker-yet: #{darken($brand-blue, 20%)};

  --brand-yellow-darker: #{darken($brand-yellow, 2%)};
  --brand-yellow-darker-yet: #{darken($brand-yellow, 20%)};

  --brand-blue: #{$brand-blue};
  --brand-yellow: #{$brand-yellow};

}



// Bootstrap Colors


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';




// Fonts

$type-family: halcom, sans-serif;
$logo-family: acier-bat-solid, sans-serif;
$code-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

:root {

  --logo-family: #{meta.inspect($logo-family)};
  --type-family: #{meta.inspect($type-family)};
  --code-family: #{meta.inspect($code-family)};
}




